<template>
  <layout-default>
    <v-layout wrap>
      <v-flex xs12 md12>
        <v-btn v-if="isAdmin()" class="pull-right" @click="addService">Create service </v-btn>
        <br />
        <v-card class="mt-4">
          <v-card-title>
            Services
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :loading="$apollo.loading"
              :headers="headers"
              :items="services"
              :search="search"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.dockerHubRepoName }}</td>
                <td class="text-xs-left">{{ item.bitbucketRepoWorkspace }}</td>
                <td class="text-xs-left">{{ item.bitbucketRepoSlug }}</td>
                <td class="text-xs-left">{{ item.helmName }}</td>
                <td class="text-xs-right text-md-right">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-title @click="removeService(item)">
                            Remove
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default.vue";
import LIST_SERVICES from "@/graphql/query/ServicesList.gql";
import REMOVE_SERVICE from "@/graphql/mutation/RemoveServiceMutation.gql";
import { isInRole } from '@/services/authService';

export default {
  name: "listServices",
  apollo: {
    services: {
      query: LIST_SERVICES
    },
  },
  components: {
    LayoutDefault,
  },
  data: () => ({
    services: [],
    search: null,
    headers: [
      { text: "Name", align: "left", value: "name" },
      { text: "Docker Hub Repo Name", align: "left", value: "dockerHubRepoName" },
      { text: "Bitbucket Repo Workspace", align: "left", value: "bitbucketRepoWorkspace" },
      { text: "Bitbucket Repo Slug", align: "left", value: "bitbucketRepoSlug" },
      { text: "Helm Name", align: "left", value: "helmName" },
      { text: "Actions", align: "right", value: "actions" }
    ],
  }),
  methods: {
    addService() {
      this.$router.push({ name: 'serviceAdd' })
    },
    async removeService(service) {
      this.$apollo
          .mutate({
            mutation: REMOVE_SERVICE,
            variables: {serviceId: service.id},
            update: (store) => {
              this.createLoading = false;
              let services = store.readQuery({ query: LIST_SERVICES });
              services.services.splice(services.services.indexOf(service), 1);
              store.writeQuery({ query: LIST_SERVICES, data: services });

              return service;
            },
          })
          .catch((error) => {
            this.createLoading = false;
            this.lastError = error;
          });
    },
    isAdmin() {
      return isInRole('admin')
    }
  }
};
</script>
